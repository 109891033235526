@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/google/material-icons/MaterialIcons-Regular.eot"); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("/assets/fonts/google/material-icons/MaterialIcons-Regular.woff2") format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

/* libre-franklin-300 - latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-300.eot"); /* IE9 Compat Modes */
  src: local("Libre Franklin Light"), local("LibreFranklin-Light"),
    url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-300.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-300.svg#LibreFranklin")
      format("svg"); /* Legacy iOS */
}
/* libre-franklin-300italic - latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: italic;
  font-weight: 300;
  src: url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-300italic.eot"); /* IE9 Compat Modes */
  src: local("Libre Franklin Light Italic"), local("LibreFranklin-LightItalic"),
    url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-300italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-300italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-300italic.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-300italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-300italic.svg#LibreFranklin") format("svg"); /* Legacy iOS */
}
/* libre-franklin-regular - latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Libre Franklin"), local("LibreFranklin-Regular"),
    url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-regular.svg#LibreFranklin") format("svg"); /* Legacy iOS */
}
/* libre-franklin-600italic - latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: italic;
  font-weight: 600;
  src: url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-600italic.eot"); /* IE9 Compat Modes */
  src: local("Libre Franklin SemiBold Italic"), local("LibreFranklin-SemiBoldItalic"),
    url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-600italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-600italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-600italic.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-600italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-600italic.svg#LibreFranklin") format("svg"); /* Legacy iOS */
}
/* libre-franklin-600 - latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-600.eot"); /* IE9 Compat Modes */
  src: local("Libre Franklin SemiBold"), local("LibreFranklin-SemiBold"),
    url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-600.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-600.svg#LibreFranklin")
      format("svg"); /* Legacy iOS */
}
/* libre-franklin-800 - latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: normal;
  font-weight: 800;
  src: url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-800.eot"); /* IE9 Compat Modes */
  src: local("Libre Franklin ExtraBold"), local("LibreFranklin-ExtraBold"),
    url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-800.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-800.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-800.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-800.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-800.svg#LibreFranklin")
      format("svg"); /* Legacy iOS */
}
/* libre-franklin-800italic - latin */
@font-face {
  font-family: "Libre Franklin";
  font-style: italic;
  font-weight: 800;
  src: url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-800italic.eot"); /* IE9 Compat Modes */
  src: local("Libre Franklin ExtraBold Italic"), local("LibreFranklin-ExtraBoldItalic"),
    url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-800italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-800italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-800italic.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-800italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/google/libre-franklin/libre-franklin-v4-latin-800italic.svg#LibreFranklin") format("svg"); /* Legacy iOS */
}

/* Source-sans-pro */

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  src: url("/assets/fonts/WOFF2/TTF/SourceSansPro-ExtraLight.ttf.woff2") format("woff2"),
    url("/assets/fonts/WOFF/OTF/SourceSansPro-ExtraLight.otf.woff") format("woff"),
    url("/assets/fonts/OTF/SourceSansPro-ExtraLight.otf") format("opentype"),
    url("/assets/fonts/TTF/SourceSansPro-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
  src: url("/assets/fonts/WOFF2/TTF/SourceSansPro-ExtraLightIt.ttf.woff2") format("woff2"),
    url("/assets/fonts/WOFF/OTF/SourceSansPro-ExtraLightIt.otf.woff") format("woff"),
    url("/assets/fonts/OTF/SourceSansPro-ExtraLightIt.otf") format("opentype"),
    url("/assets/fonts/TTF/SourceSansPro-ExtraLightIt.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  src: url("/assets/fonts/WOFF2/TTF/SourceSansPro-Light.ttf.woff2") format("woff2"),
    url("/assets/fonts/WOFF/OTF/SourceSansPro-Light.otf.woff") format("woff"),
    url("/assets/fonts/OTF/SourceSansPro-Light.otf") format("opentype"),
    url("/assets/fonts/TTF/SourceSansPro-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  src: url("/assets/fonts/WOFF2/TTF/SourceSansPro-LightIt.ttf.woff2") format("woff2"),
    url("/assets/fonts/WOFF/OTF/SourceSansPro-LightIt.otf.woff") format("woff"),
    url("/assets/fonts/OTF/SourceSansPro-LightIt.otf") format("opentype"),
    url("/assets/fonts/TTF/SourceSansPro-LightIt.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url("/assets/fonts/WOFF2/TTF/SourceSansPro-Regular.ttf.woff2") format("woff2"),
    url("/assets/fonts/WOFF/OTF/SourceSansPro-Regular.otf.woff") format("woff"),
    url("/assets/fonts/OTF/SourceSansPro-Regular.otf") format("opentype"),
    url("/assets/fonts/TTF/SourceSansPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  src: url("/assets/fonts/WOFF2/TTF/SourceSansPro-It.ttf.woff2") format("woff2"),
    url("/assets/fonts/WOFF/OTF/SourceSansPro-It.otf.woff") format("woff"),
    url("/assets/fonts/OTF/SourceSansPro-It.otf") format("opentype"),
    url("/assets/fonts/TTF/SourceSansPro-It.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  src: url("/assets/fonts/WOFF2/TTF/SourceSansPro-Semibold.ttf.woff2") format("woff2"),
    url("/assets/fonts/WOFF/OTF/SourceSansPro-Semibold.otf.woff") format("woff"),
    url("/assets/fonts/OTF/SourceSansPro-Semibold.otf") format("opentype"),
    url("/assets/fonts/TTF/SourceSansPro-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  src: url("/assets/fonts/WOFF2/TTF/SourceSansPro-SemiboldIt.ttf.woff2") format("woff2"),
    url("/assets/fonts/WOFF/OTF/SourceSansPro-SemiboldIt.otf.woff") format("woff"),
    url("/assets/fonts/OTF/SourceSansPro-SemiboldIt.otf") format("opentype"),
    url("/assets/fonts/TTF/SourceSansPro-SemiboldIt.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  src: url("/assets/fonts/WOFF2/TTF/SourceSansPro-Bold.ttf.woff2") format("woff2"),
    url("/assets/fonts/WOFF/OTF/SourceSansPro-Bold.otf.woff") format("woff"),
    url("/assets/fonts/OTF/SourceSansPro-Bold.otf") format("opentype"),
    url("/assets/fonts/TTF/SourceSansPro-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  src: url("/assets/fonts/WOFF2/TTF/SourceSansPro-BoldIt.ttf.woff2") format("woff2"),
    url("/assets/fonts/WOFF/OTF/SourceSansPro-BoldIt.otf.woff") format("woff"),
    url("/assets/fonts/OTF/SourceSansPro-BoldIt.otf") format("opentype"),
    url("/assets/fonts/TTF/SourceSansPro-BoldIt.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  src: url("/assets/fonts/WOFF2/TTF/SourceSansPro-Black.ttf.woff2") format("woff2"),
    url("/assets/fonts/WOFF/OTF/SourceSansPro-Black.otf.woff") format("woff"),
    url("/assets/fonts/OTF/SourceSansPro-Black.otf") format("opentype"),
    url("/assets/fonts/TTF/SourceSansPro-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  src: url("/assets/fonts/WOFF2/TTF/SourceSansPro-BlackIt.ttf.woff2") format("woff2"),
    url("/assets/fonts/WOFF/OTF/SourceSansPro-BlackIt.otf.woff") format("woff"),
    url("/assets/fonts/OTF/SourceSansPro-BlackIt.otf") format("opentype"),
    url("/assets/fonts/TTF/SourceSansPro-BlackIt.ttf") format("truetype");
}
/****** Fontello ******/
@font-face {
  font-family: "animals";
  src: url("/assets/fonts/animals/animals.eot");
  src: url("/assets/fonts/animals/animals.eot") format("embedded-opentype"),
    url("/assets/fonts/animals/animals.woff2") format("woff2"), url("/assets/fonts/animals/animals.woff") format("woff"),
    url("/assets/fonts/animals/animals.ttf") format("truetype"), url("/assets/fonts/animals/animals.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("/assets/fonts/google/roboto/roboto-v20-latin-300.eot"); /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"),
    url("/assets/fonts/google/roboto/roboto-v20-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/google/roboto/roboto-v20-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/google/roboto/roboto-v20-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("/assets/fonts/google/roboto/roboto-v20-latin-300.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/google/roboto/roboto-v20-latin-300.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/google/roboto/roboto-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"),
    url("/assets/fonts/google/roboto/roboto-v20-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/google/roboto/roboto-v20-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/google/roboto/roboto-v20-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("/assets/fonts/google/roboto/roboto-v20-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/google/roboto/roboto-v20-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/google/roboto/roboto-v20-latin-500.eot"); /* IE9 Compat Modes */
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("/assets/fonts/google/roboto/roboto-v20-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/google/roboto/roboto-v20-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/google/roboto/roboto-v20-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("/assets/fonts/google/roboto/roboto-v20-latin-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/google/roboto/roboto-v20-latin-500.svg#Roboto") format("svg"); /* Legacy iOS */
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'animals';
    src: url('../font/animals.svg?80689351#animals') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "animals";
  font-style: normal;
  font-weight: normal;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-cow:before {
  content: "\e800";
} /* '' */
.icon-goat:before {
  content: "\e802";
} /* '' */
.icon-horse:before {
  content: "\e803";
} /* '' */
.icon-sheep:before {
  content: "\e804";
} /* '' */
.icon-pig:before {
  content: "\e805";
} /* '' */
.icon-wheat:before {
  content: "\e807";
} /* '' */
.icon-grapes:before {
  content: "\e808";
} /* '' */
.icon-hen:before {
  content: "\e809";
} /* '' */
